<template>
  <button
    @click.stop.prevent="showModal = true"
    class="btn btn-sm btn-dark d-inline-flex align-items-center"
  >
    <fa-icon
      v-if="!dismissible"
      :icon="['fas', 'play-circle']"
      class="me-1"
    ></fa-icon>

    <span class="text-start">{{ item.name }}</span>

    <fa-icon
      @click.stop.prevent="$emit('remove', item)"
      v-if="dismissible"
      :icon="['fas', 'times-circle']"
      class="ms-2"
    ></fa-icon>

    <portal to="modal">
      <transition name="modal">
        <ResourcePreviewModal
          @close="showModal = false"
          v-if="showModal"
          v-model="showModal"
          :url="item.url"
        ></ResourcePreviewModal>
      </transition>
    </portal>
  </button>
</template>

<script>
export default {
  name: 'VideoTag',
  components: {
    ResourcePreviewModal: () => import('@/components/modal/ResourcePreviewModal'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
button {
  border-radius: 1000rem;
}
</style>
